import { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Grid, Avatar, Box, Popper } from "@material-ui/core";

import { HealthFactorProgressBar, HealthTooltip } from "components";
import { NavLink as Link } from "react-router-dom";
import { TokenType } from "utils/dex/enum/tokenType";
import { getTokenType, getLogoBySymbolAndName } from "utils/token";
import { useWallet } from "hooks";

const useStyles = makeStyles((theme) => ({
  container: { display: "flex", justifyContent: "center" },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  md: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatar: {
    marginRight: 15,
    borderRadius: "50%",
  },
  whiteBg: { background: "white" },
  lpToken: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
  },
  spacingToken: {
    marginRight: "2px",
  },
  customTooltip: {
    zIndex: 99,
    pointerEvents: "none",
    marginTop: 10,
    minWidth: 190,
    padding: 20,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    filter: "drop-shadow(rgba(0, 0, 0, 0.8) 0 2px 10px)",
    "&:before": {
      content: "''",
      position: "absolute",
      top: -4,
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
      width: 15,
      height: 15,
      borderRadius: 2,
      backgroundColor: theme.palette.text.primary,
    },
  },
  flexWrap: {
    flexWrap: "nowrap",
  },
  link: {
    color: "unset",
    "& p": {
      fontStyle: "unset",
    },
    textDecoration: "none",
    "&:hover .underline": {
      textDecoration: "underline",
    },
  },
  text4626: {
    color: "darkblue",
    backgroundColor: "cadetblue",
    borderRadius: 10,
    fontWeight: 900,
    height: "fit-content",
    padding: "2px 6px",
    marginTop: -8,
    marginLeft: -35,
    zIndex: 1,
    fontSize: "10px !important",
  },
}));

const CoinInfo = ({
  logoUrl,
  symbol,
  name,
  address,
  underlyingTokens = [],
  children,
  size,
  description,
  healthFactor,
  currentPrice,
  lendingSymbol,
  whiteBg = false,
  link,
  chainId,
}) => {
  const classes = useStyles();
  const { chainId: connectedChainId } = useWallet();
  const safetyBuffer = healthFactor && healthFactor !== 0 ? 1 - 1 / healthFactor : null;
  const [isOpen, setOpen] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverHover = () => {
    setOpen(true);
  };

  const popoverLeave = () => {
    setOpen(false);
  };

  const [logoLPOne, logoLPTwo] = useMemo(() => {
    if (underlyingTokens.length < 2) {
      return ["", ""];
    }
    const token0 = underlyingTokens[0];
    const token1 = underlyingTokens[1];
    if (!token0 || !token1) {
      return ["", ""];
    }
    return [
      getLogoBySymbolAndName(
        token0.symbol,
        token0.name,
        token0.address,
        chainId || connectedChainId
      ),
      getLogoBySymbolAndName(
        token1.symbol,
        token1.name,
        token1.address,
        chainId || connectedChainId
      ),
    ];
  }, [chainId, underlyingTokens, connectedChainId]);

  const logoERC4626 = useMemo(() => {
    if (underlyingTokens.length !== 1) {
      return "";
    }
    const token0 = underlyingTokens[0];

    return getLogoBySymbolAndName(
      token0.symbol,
      token0.name,
      token0.address,
      chainId || connectedChainId
    );
  }, [chainId, connectedChainId, underlyingTokens]);

  const logoERC20 = useMemo(() => {
    if (underlyingTokens.length !== 0) {
      return "";
    }
    if (logoUrl) {
      return logoUrl;
    }
    return getLogoBySymbolAndName(symbol, name, address, chainId || connectedChainId);
  }, [address, chainId, connectedChainId, logoUrl, name, symbol, underlyingTokens.length]);

  const tokenType = useMemo(() => getTokenType(underlyingTokens), [underlyingTokens]);

  const getCoinInfo = () => (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item className={classes.container}>
        {tokenType === TokenType.LP && (
          <Grid container className={classes.flexWrap}>
            <Grid item className={classes.spacingToken}>
              <Avatar src={logoLPOne} alt="Coin Logo" className={classes.lpToken} />
            </Grid>
            <Grid item>
              <Avatar
                src={logoLPTwo}
                style={{
                  marginLeft: -12,
                }}
                alt="Coin Logo"
                className={classes.lpToken}
              />
            </Grid>
          </Grid>
        )}
        {tokenType === TokenType.ERC4626 && (
          <Grid container className={classes.flexWrap}>
            <Grid item>
              <Avatar
                src={logoERC4626}
                alt="Coin Logo"
                classes={{ root: clsx(classes[size]) }}
                className={[classes.avatar, whiteBg && classes.whiteBg]}
              />
            </Grid>
            <Grid item className={classes.text4626}>
              4626
            </Grid>
          </Grid>
        )}
        {tokenType === TokenType.ERC20 && (
          <Avatar
            src={logoERC20}
            alt="Coin Logo"
            classes={{ root: clsx(classes[size]) }}
            className={[classes.avatar, whiteBg && classes.whiteBg]}
          />
        )}
      </Grid>
      <Grid item>
        <Typography className="underline">{children}</Typography>
        {description && <Typography color="primary">{description}</Typography>}
        {healthFactor && (
          <>
            <Box ref={popoverAnchor} onMouseEnter={popoverHover} onMouseLeave={popoverLeave}>
              <HealthFactorProgressBar
                value={safetyBuffer}
                isSafetyBuffer
                size="small"
                price={currentPrice}
                collateralSymbol={children}
                lendingSymbol={lendingSymbol}
              />
            </Box>
            <Popper
              open={isOpen}
              anchorEl={popoverAnchor.current}
              className={classes.customTooltip}
              PaperProps={{
                onMouseEnter: popoverHover,
                onMouseLeave: popoverLeave,
              }}
            >
              <HealthTooltip
                value={healthFactor}
                price={currentPrice}
                collateralSymbol={children}
                lendingSymbol={lendingSymbol}
              />
            </Popper>
          </>
        )}
      </Grid>
    </Grid>
  );

  return link ? (
    <Link to={link} className={classes.link}>
      {getCoinInfo()}
    </Link>
  ) : (
    getCoinInfo()
  );
};

CoinInfo.propTypes = {
  symbol: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  underlyingTokens: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  size: PropTypes.oneOf(["large", "default", "md"]),
  description: PropTypes.string,
  healthFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CoinInfo.defaultProps = {
  size: "default",
  description: "",
  healthFactor: false,
  underlyingTokens: [],
};

export default CoinInfo;
