import BigNumber from "bignumber.js";
import { formatUnits, parseUnits } from "utils/number";
import { get, isNaN, pick } from "lodash";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";

import { BorrowContextProvider } from "context/contracts/BorrowContextProvider";
import useAllBorrowData from "hooks/contexts/BorrowContext/useAllBorrowData";
import usePriceLendingToken from "hooks/contexts/LendingAssetContext/usePriceLendingToken";
import { useFetchLeverageBorrowData } from "hooks/query/graphQL/useFetchLeveragedBorrowData";
import { MIN_AMPLIFY } from "constants/contract";
import { useWallet } from "hooks";
import { getExposureLimit } from "utils/ethereum/getExposureLimit";
import { MainNetworkSupported, isEqualLowerString } from "utils/network";
import { constants } from "ethers";
import { getLendingTokenCount } from "utils/ethereum/calculateLendingTokenCount";
import useDecimalToken from "hooks/contexts/BorrowContext/useDecimalToken";
import { useGetBlocksPer } from "hooks/common/useGetBlocksPer";
import { getRateInfo } from "utils/ethereum/getRateInfo";
import { useRouterMutations } from "hooks/mutation";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { MARGIN_BUFFER } from "app/constants";
import { isWrapNative } from "utils/token";

function getBaseLog(x, y) {
  return Math.log10(x) / Math.log10(y);
}

export const LeverageContext = createContext();

const useLeveragePositions = (collaterals = []) =>
  useMemo(
    () =>
      collaterals.map((collateral) => {
        const collateralData = pick(collateral, ["symbol", "balance", "address", "decimal"]);

        const notionalExp = new BigNumber(
          formatUnits(
            get(collateral, "loanBodyBN", "0"),
            get(collateral, ["lendingTokenData", "decimal"], "0")
          )
        )
          .multipliedBy(get(collateral, ["lendingTokenData", "price"], "0"))
          .toString();

        const margin = new BigNumber(
          formatUnits(get(collateral, "depositedAmountBN", 0), get(collateral, ["decimal"], "0"))
        )
          .multipliedBy(get(collateral, ["price"], "0"))
          .minus(notionalExp)
          .toString();

        return { ...collateralData, margin, notionalExp };
      }),
    [collaterals]
  );

const ContextProvider = ({ children }) => {
  const [shortAssetAddress, setShortAssetAddress] = useState();
  const [longAssetAddress, setLongAssetAddress] = useState();
  const hideZero = useState(false);
  const [margin, setMargin] = useState("");
  const [notionalExp, setNotionalExp] = useState("");
  const [safeBuffer, setSafeBuffer] = useState("");
  const [lendingTokenCount, setLendingTokenCount] = useState("");
  const [amplification, setAmplification] = useState(MIN_AMPLIFY);
  const [estDayLiquidation, dispatchEstDayLiquidation] = useState(0);
  const [resetForm, setResetForm] = useState(false);
  const { laveragedBorrowList, refetch: refetchLaveragedBorrowList } = useFetchLeverageBorrowData();
  const [exposureLimit, setExposureLimit] = useState(null);
  const { chainId, provider } = useWallet();
  const { blocksPerMinute } = useGetBlocksPer();

  const [slippage, setSlippage] = useState({
    amount: 0,
    priceDiscrepancy: 0,
    loading: false,
  });

  const { getAmountOut } = useRouterMutations();

  const [interestRatePerBlock, setInterestRatePerBlock] = useState(0);
  const borrowContext = useBorrowContext();
  const lendingPairToken = get(borrowContext, ["pairToken"], {});

  const decimalList = useDecimalToken();
  const usdcToken = localStorage.getItem("usdcToken");
  const usdcDecimal = useMemo(() => decimalList[usdcToken], [decimalList, usdcToken]);

  const pairToken = useState(true);

  const resetStates = () => {
    setResetForm(!resetForm);
    setAmplification(MIN_AMPLIFY);
    setMargin("");
    setNotionalExp("");
    setSafeBuffer("");
    setLendingTokenCount("");
  };

  const tokenAllData = useAllBorrowData();
  const priceOfLendingToken = usePriceLendingToken();

  // #region Calculated values, using for both Amplify page and Margin Trade page
  const lendingList = useMemo(() => {
    const tokenList = get(tokenAllData, ["lendingList"], []).map((o) => ({
      ...o,
      price: priceOfLendingToken[o.address],
    }));
    return tokenList;
  }, [priceOfLendingToken, tokenAllData]);

  const leveragePositions = useLeveragePositions(
    get(tokenAllData, ["collateralList"], [])
      .filter((token) => token?.isLeverage)
      .map((p) => ({
        ...p,
        lendingTokenData: {
          ...p.lendingTokenData,
          price: get(priceOfLendingToken, [p.lendingToken], 0),
        },
      }))
  );

  const collateralList = useMemo(() => {
    const tokenList = get(tokenAllData, ["collateralList"], []);

    return !hideZero[0]
      ? tokenList
      : tokenList.filter((item) => +item.balance > 0 || +item.depositedAmount > 0);
  }, [hideZero, tokenAllData]);

  const isHavePair = useCallback(
    (collateral, lendingToken) =>
      !isEqualLowerString(get(collateral, ["pairToken", lendingToken]), constants.AddressZero),
    []
  );

  const shortAssetSelected = useMemo(() => {
    if (!lendingList || lendingList?.length === 0) {
      return lendingList[0];
    }

    if (MainNetworkSupported.includes(+chainId)) {
      const selectedShortAsset = lendingList.find((x) => x.address === shortAssetAddress);

      return selectedShortAsset || lendingList[0];
    }
    // eslint-disable-next-line no-else-return
    else {
      if (!shortAssetAddress) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < lendingList.length; i++) {
          const lendingToken = lendingList[i];
          const arrayHavePair = collateralList.filter((collat) =>
            isHavePair(collat, lendingToken.address)
          );

          if (arrayHavePair.length !== 0) {
            const isUSDCToken = isEqualLowerString(lendingToken.address, usdcToken);
            const pairWithUsdc = isUSDCToken
              ? true
              : !isEqualLowerString(lendingPairToken[lendingToken.address], constants.AddressZero);
            if (pairWithUsdc) return lendingToken;
          }
        }
      }
      const selectedShortAsset =
        lendingList.find((x) => x.address === shortAssetAddress) || lendingList[0];
      return selectedShortAsset;
    }
  }, [
    lendingList,
    chainId,
    shortAssetAddress,
    collateralList,
    isHavePair,
    lendingPairToken,
    usdcToken,
  ]);

  const longAssetSelected = useMemo(() => {
    if (!collateralList || collateralList?.length === 0) {
      return collateralList[0];
    }

    if (MainNetworkSupported.includes(+chainId)) {
      const selectedLongAsset = collateralList.find((x) => x.address === longAssetAddress);

      if (hideZero[0]) {
        return selectedLongAsset?.balance ? selectedLongAsset : collateralList[0];
      }

      return selectedLongAsset || collateralList[0];
    }

    const selectedLongAsset =
      collateralList.find(
        (x) =>
          x.address === longAssetAddress &&
          ((isHavePair(x, shortAssetSelected?.address) && isHavePair(x, usdcToken)) ||
            x?.underlyingTokens?.length > 0)
      ) ||
      collateralList.filter(
        (x) => isHavePair(x, shortAssetSelected?.address) && isHavePair(x, usdcToken)
      )[0];

    if (hideZero[0]) {
      return selectedLongAsset?.balance ? selectedLongAsset : undefined;
    }

    return selectedLongAsset;
  }, [
    collateralList,
    chainId,
    hideZero,
    longAssetAddress,
    isHavePair,
    shortAssetSelected?.address,
    usdcToken,
  ]);

  useEffect(() => {
    const fetchAmountOut = async () => {
      if (!margin) {
        setSlippage({
          amount: 0,
          priceDiscrepancy: 0,
        });
      } else {
        const marginConvert = longAssetSelected?.price ? margin / longAssetSelected.price : "0";

        const { amountOut } = await getAmountOut({
          maxAmountIn: parseUnits(
            marginConvert.toFixed(longAssetSelected.decimal),
            longAssetSelected.decimal
          ),
          inputToken: longAssetSelected.address,
          outputToken: shortAssetSelected.address,
        });

        const amountAfterSwap = Number(formatUnits(amountOut, shortAssetSelected.decimal));

        const priceLendingToken = amountAfterSwap * shortAssetSelected.price;
        const pricePrjToken = marginConvert * longAssetSelected.price;
        const priceDifference = (
          ((pricePrjToken - priceLendingToken) / priceLendingToken) *
          100
        ).toFixed(2);

        setSlippage({
          amount: amountAfterSwap,
          priceDiscrepancy: priceDifference,
        });
      }
    };

    if (MainNetworkSupported.includes(+chainId)) {
      if (margin) {
        fetchAmountOut();
      } else {
        setSlippage({
          amount: 0,
          priceDiscrepancy: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    longAssetSelected?.address,
    longAssetSelected?.price,
    shortAssetSelected?.price,
    shortAssetSelected?.address,
    margin,
    chainId,
    getAmountOut,
  ]);

  const [minMargin, maxMargin] = useMemo(() => {
    const deposited = get(longAssetSelected, ["depositedAmount"], 0);
    const balance = get(longAssetSelected, ["balance"], 0);
    const price = get(longAssetSelected, ["price"], 0);
    const isNative = isWrapNative(longAssetSelected?.address || "", chainId);
    let buffer = isNative ? MARGIN_BUFFER : 0;
    if (buffer > Number(balance)) {
      buffer = balance;
    }

    const minMarginBN = new BigNumber(deposited).multipliedBy(price);
    const maxMarginBN = new BigNumber(deposited).plus(balance).minus(buffer).multipliedBy(price);

    return [minMarginBN.toString(), maxMarginBN.toString()];
  }, [chainId, longAssetSelected]);

  const maxAmplification = useMemo(() => {
    const longLvr = get(longAssetSelected, "lvr", 0);
    const shortLvr = get(shortAssetSelected, "lvr", 0);
    const lvr = longLvr * shortLvr;
    return new BigNumber(1).dividedBy(new BigNumber(1).minus(lvr)).toString();
  }, [longAssetSelected, shortAssetSelected]);
  // #endregion

  useEffect(() => {
    if (shortAssetSelected?.fLendingToken) {
      (async () => {
        try {
          const { lastInterestRate } = await getRateInfo(
            shortAssetSelected?.fLendingToken,
            chainId,
            provider
          );
          setInterestRatePerBlock(+lastInterestRate);
        } catch (error) {
          setInterestRatePerBlock(0);
        }
      })();
    }
  }, [chainId, shortAssetSelected?.fLendingToken, provider]);

  /**
   * Calculate estimated day liquidation on worker
   */
  useEffect(() => {
    if (!safeBuffer || !+safeBuffer || !+margin || !+interestRatePerBlock) {
      dispatchEstDayLiquidation(0);
    } else {
      const chainBlockPerMinute = +blocksPerMinute;

      const baseX = new BigNumber(1).plus(safeBuffer);
      const interestRatePerBlockPow10 = new BigNumber(interestRatePerBlock).multipliedBy(
        new BigNumber(10).exponentiatedBy(-18)
      );
      const onePlusInterestPow10 = new BigNumber(1).plus(interestRatePerBlockPow10);
      const baseY = Number(onePlusInterestPow10) ** (chainBlockPerMinute * 60 * 24);

      const est = new BigNumber(getBaseLog(baseX, baseY)).lt(0) ? 0 : getBaseLog(baseX, baseY);

      dispatchEstDayLiquidation(est);
    }
  }, [shortAssetSelected?.borrowRate, safeBuffer, margin, interestRatePerBlock, blocksPerMinute]);

  const isMarginInvalid = useMemo(() => {
    if (margin === "") return true;
    const marginBN = new BigNumber(margin);
    return marginBN.lt(minMargin) || marginBN.gt(maxMargin) || marginBN.isZero();
  }, [maxMargin, minMargin, margin]);

  useEffect(() => {
    if (longAssetSelected?.address && shortAssetSelected?.address) {
      (async () => {
        const result = await getExposureLimit(
          longAssetSelected.address,
          shortAssetSelected.address,
          {
            chainId,
            provider,
          }
        );
        if (result && usdcDecimal) {
          const parsedExposureLimit = formatUnits(result.toString(), usdcDecimal);
          setExposureLimit(parsedExposureLimit);
        } else {
          setExposureLimit(null);
        }
      })();
    }
  }, [
    usdcDecimal,
    chainId,
    longAssetSelected?.address,
    shortAssetSelected?.address,
    shortAssetSelected?.decimal,
    provider,
  ]);

  /**
   * Calculate lending token count
   */
  useEffect(() => {
    if (!isNaN(+notionalExp) && notionalExp && shortAssetSelected?.address) {
      (async () => {
        const result = await getLendingTokenCount({
          chainId,
          provider,
          notionalValue: notionalExp,
          collateralTokenAddress: longAssetSelected?.address,
          lendingTokenAddress: shortAssetSelected?.address,
        });
        setLendingTokenCount(formatUnits(result, 0));
      })();
    } else {
      setLendingTokenCount("");
    }
  }, [chainId, longAssetSelected?.address, notionalExp, shortAssetSelected?.address, provider]);

  const contextValue = {
    margin: [margin, setMargin],
    hideZero,
    setShortAssetAddress,
    amplification: [amplification, setAmplification],
    setLongAssetAddress,
    collateralList,
    longAssetSelected,
    notionalExp,
    safeBuffer,
    shortAssetSelected,
    lendingList,
    minMargin,
    maxMargin,
    maxAmplification,
    estDayLiquidation,
    leveragePositions,
    isMarginInvalid,
    resetStates,
    resetForm,
    laveragedBorrowList,
    refetchLaveragedBorrowList,
    setNotionalExp,
    setSafeBuffer,
    exposureLimit,
    setLendingTokenCount,
    lendingTokenCount,
    pairToken,
    setExposureLimit,
    slippage,
  };

  return <LeverageContext.Provider value={contextValue}>{children}</LeverageContext.Provider>;
};

export const LeverageContextProvider = ({ children }) => (
  <BorrowContextProvider>
    <ContextProvider>{children}</ContextProvider>
  </BorrowContextProvider>
);
