/* eslint-disable prefer-destructuring */
import { useCallback, useMemo } from "react";
import { ERC20TokenABI, MultiCallZkSyncABI } from "utils/ethereum/abi";
import { formatUnits } from "utils/number";
import { ZERO_ADDRESS, networks } from "utils/network";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { useQuery } from "react-query";
import {
  GET_PRJ_TOKEN_WITHOUT_ACCOUNT,
  getPrice,
} from "pages/BorrowerNewDashboard/hooks/useTokenSupported";
import {
  getPriceProviderAggregatorZksyncContract,
  getPrimaryIndexTokenZksync,
} from "utils/ethereum/contracts";
import { GET_MARKET_STATE, handleGetLastValue } from "hooks/query/graphQL/useMarketChart";
import { isWrapNative, wrapNativeToken, mappingTokenInfo } from "utils/token";
import { ContractName } from "constants/contractName";
import { PITContractMethod, ERC20TokenMethod } from "constants/methodName";
import { getClient } from "utils/client/graphql.client";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { USD_DECIMAL } from "constants/contract";

const decodeMulticallResult = (results, requests) => {
  const decoded = requests.map((data, index) => {
    const { methodName, iface } = data;
    const result = results[index]?.returnData;

    let decodeValue;

    try {
      decodeValue = iface.decodeFunctionResult(methodName, result);
    } catch (e) {
      if (methodName === ERC20TokenMethod.name || methodName === ERC20TokenMethod.symbol) {
        decodeValue = utils.parseBytes32String(result);
      } else {
        throw e;
      }
    }

    return {
      methodName,
      value: decodeValue,
    };
  });

  const returnedData = {};
  decoded.forEach((data) => {
    returnedData[data.methodName] = data.value;
  });

  return returnedData;
};

const getClientByChainNumber = (chainId) => getClient(`0x${Number(chainId).toString(16)}`);

export const useAllTokens = () => {
  const tokenInAllNetworks = useQuery(["get-all-token-data"], async () => {
    const promises = [];
    networks.forEach((network) => {
      const client = getClientByChainNumber(network.id);
      promises.push(client.query({ query: GET_PRJ_TOKEN_WITHOUT_ACCOUNT }));
    });
    const tokenData = await Promise.all(promises);
    const result = [];
    tokenData.forEach((res, index) => {
      const network = networks[index];
      result.push({
        ...res.data,
        network,
      });
    });
    return result;
  });

  return tokenInAllNetworks;
};

export const useTokenInfo = (address, chainId) => {
  const selectedChainId = useMemo(() => `0x${Number(chainId).toString(16)}`, [chainId]);
  const provider = useMemo(() => {
    const network = networks.find((n) => n.id === Number(chainId));
    if (!network) return null;
    return new ethers.providers.JsonRpcProvider(network.rpcUrls.default.http[0], {
      chainId: Number(chainId),
      name: network.name,
    });
  }, [chainId]);

  const PitInstance = useMemo(() => getPrimaryIndexTokenZksync(selectedChainId), [selectedChainId]);

  const multiCallContract = useMemo(() => {
    if (!provider) return null;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [selectedChainId, provider]);

  const results = useQuery(
    ["landing-token-info", address],
    async () => {
      const erc20Iface = new utils.Interface(ERC20TokenABI);
      const pitIface = new utils.Interface(PitInstance.abi);
      const listRequest = [];
      listRequest.push(
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData(ERC20TokenMethod.name, []),
          reference: address,
          methodName: ERC20TokenMethod.name,
          methodParameters: [],
          value: 0,
          contractName: ContractName.ERC20Token,
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData(ERC20TokenMethod.symbol, []),
          reference: address,
          methodName: ERC20TokenMethod.symbol,
          methodParameters: [],
          value: 0,
          contractName: ContractName.ERC20Token,
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData(ERC20TokenMethod.decimals, []),
          reference: address,
          methodName: ERC20TokenMethod.decimals,
          methodParameters: [],
          value: 0,
          contractName: ContractName.ERC20Token,
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData(PITContractMethod.lendingTokenInfo, [address]),
          reference: address,
          methodName: PITContractMethod.lendingTokenInfo,
          methodParameters: [address],
          value: 0,
          contractName: ContractName.PITContract,
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData(PITContractMethod.borrowLimitPerLendingToken, [
            address,
          ]),
          reference: address,
          methodName: PITContractMethod.borrowLimitPerLendingToken,
          methodParameters: [address],
          value: 0,
          contractName: ContractName.PITContract,
        }
      );
      const multicallRes = await multiCallContract.callStatic
        .aggregate3Value(listRequest, {
          value: 0,
        })
        .catch((error) => {
          console.log("Multicall request fail", listRequest);
          throw error;
        });
      const values = decodeMulticallResult(multicallRes, listRequest);
      return { ...values };
    },
    { enabled: !!address && !!multiCallContract }
  );

  const tokenInfo = useMemo(() => {
    const isNative = isWrapNative(address, selectedChainId);
    const nativeCoin = wrapNativeToken(selectedChainId);
    const token = {
      address,
      name: "",
      symbol: "",
      decimal: 18,
      lvr: 0,
      debtLimitUds: 0,
      bLendingToken: "",
      priceProvider: "",
    };
    if (results.isFetched && results.isSuccess) {
      const { name, symbol, decimals, lendingTokenInfo, borrowLimitPerLendingToken } = results.data;
      token.name = isNative ? nativeCoin.name : name[0];
      token.symbol = isNative ? nativeCoin.symbol : symbol[0];
      token.decimal = decimals[0];
      token.debtLimitUds = formatUnits(borrowLimitPerLendingToken[0], USD_DECIMAL);
      token.lvr = lendingTokenInfo[3].numerator / lendingTokenInfo[3].denominator;
      token.bLendingToken = lendingTokenInfo[2];
    }
    return mappingTokenInfo(token, selectedChainId);
  }, [address, results, selectedChainId]);

  return {
    loading: false,
    ...tokenInfo,
  };
};

export const useBlendingTokenInfo = (address, chainId) => {
  const marketData = useQuery(
    ["get-all-market-data", address, chainId],
    async () => {
      let hasNextPage = true;
      let cursor = 0;
      const allResults = {};
      const client = getClientByChainNumber(chainId);
      while (hasNextPage) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await client.query({
          query: GET_MARKET_STATE,
          variables: {
            lendingToken: address,
            skip: cursor,
            startAt: 0,
            limit: 1000,
          },
        });

        hasNextPage = false;
        // eslint-disable-next-line no-loop-func
        Object.keys(data).forEach((key) => {
          if (!allResults[key]) {
            allResults[key] = [];
          }
          allResults[key] = allResults[key].concat(data[key]);
          if (data[key].length === 1000) {
            hasNextPage = true;
          }
        });
        cursor += 1000;
      }
      return allResults;
    },
    { enabled: !!address }
  );

  const [totalSupplyUsd, totalBorrowsUsd, borrowApy, lenderApy] = useMemo(() => {
    if (!!address && marketData.data) {
      const {
        borrowingAPYHistories,
        lenderAPYHistories,
        outstandingHistories,
        lenderAggregateCapitalDepositedHistories,
      } = handleGetLastValue(marketData.data);

      return [
        lenderAggregateCapitalDepositedHistories?.amount,
        outstandingHistories?.amount,
        borrowingAPYHistories?.amount,
        lenderAPYHistories?.amount,
      ];
    }
    return [0, 0, 0, 0];
  }, [address, marketData.data]);

  return {
    loading: marketData.isLoading,
    totalSupplyUsd,
    totalBorrowsUsd,
    borrowApy,
    lenderApy,
  };
};

export const usePriceToken = (address, underlyingTokens = [], chainId) => {
  const selectedChainId = useMemo(() => `0x${Number(chainId).toString(16)}`, [chainId]);
  const provider = useMemo(() => {
    const network = networks.find((n) => n.id === Number(chainId));
    if (!network) return null;
    return new ethers.providers.JsonRpcProvider(network.rpcUrls.default.http[0], {
      chainId: Number(chainId),
      name: network.name,
    });
  }, [chainId]);
  const multiCallContract = useMemo(() => {
    if (!provider) return null;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [selectedChainId, provider]);

  const PriceInstance = useMemo(
    () => getPriceProviderAggregatorZksyncContract(selectedChainId),
    [selectedChainId]
  );

  const PitInstance = useMemo(() => getPrimaryIndexTokenZksync(selectedChainId), [selectedChainId]);

  const getPriceFromPit = useCallback(async () => {
    const PitContract = new Contract(PitInstance.address, PitInstance.abi, provider);
    const PriceContract = new Contract(PriceInstance.address, PriceInstance.abi, provider);
    const TokenContract = new Contract(address, ERC20TokenABI, provider);
    const tokensNeedToUpdatePrice =
      underlyingTokens?.length > 0
        ? underlyingTokens.map((underlying) => underlying.address)
        : [address];
    const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
      Array.from(new Set(tokensNeedToUpdatePrice)),
      PriceContract
    );

    const decimals = await TokenContract.decimals();
    const oneToken = BigNumber.from(10).pow(decimals);
    const { collateralEvaluation } =
      await PitContract.callStatic.getTokenEvaluationWithUpdatePrices(
        address,
        oneToken,
        priceIds,
        updateData,
        { value: payableAmount }
      );
    return (Number(collateralEvaluation) / 10 ** Number(USD_DECIMAL)).toString();
  }, [PitInstance, PriceInstance, address, provider, underlyingTokens]);

  const getPriceToken = useQuery(
    ["get-price-token", address],
    async () => {
      try {
        const prices = await getPrice(
          [{ address, underlyingTokens }],
          PriceInstance,
          multiCallContract,
          provider
        );
        return prices[address];
      } catch (error) {
        return getPriceFromPit();
      }
    },
    {
      enabled: !!address && address !== ZERO_ADDRESS && !!provider,
    }
  );

  return {
    price: getPriceToken.data,
    error: getPriceToken.isError,
    isLoading: getPriceToken.isLoading,
  };
};
