import { constants, providers } from "ethers";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  zkSync,
} from "wagmi/chains";
import { ReactComponent as EthereumIcon } from "assets/ethereum.svg";
import { ReactComponent as PolygonIcon } from "assets/polygon.svg";
import { ReactComponent as ArbitrumIcon } from "assets/svg/arbitrum.svg";
import { ReactComponent as OptimismIcon } from "assets/svg/optimism.svg";
import { ReactComponent as ZkSyncIcon } from "assets/zksync.svg";
import { CHAINID_NETWORK } from "constants/NetworkChainId";
import { toHex } from "./number";

export const isEqualLowerString = (ad1 = constants.AddressZero, ad2 = constants.AddressZero) =>
  ad1.toLowerCase() === ad2.toLowerCase();

export const isZksyncNetwork = (chainId) => chainId === process.env.REACT_APP_CHAIN_ID_ZKSYNC;

export const isIncludeNetwork = {
  polygon: process.env.REACT_APP_INCLUDE_POLYGON === "true",
  ethereum: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
  arbitrum: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
  optimism: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
  zkSync: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
};

const isIncludeNetworkArray = [
  {
    name: "polygon",
    isOn: process.env.REACT_APP_INCLUDE_POLYGON === "true",
    mainnet: polygon,
    testnet: polygonMumbai,
  },
  {
    name: "ethereum",
    isOn: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
    mainnet,
    testnet: goerli,
  },
  {
    name: "arbitrum",
    isOn: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
    mainnet: arbitrum,
    testnet: arbitrumGoerli,
  },
  {
    name: "optimism",
    isOn: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
    mainnet: optimism,
    testnet: optimismGoerli,
  },
  {
    name: "zkSync",
    isOn: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
    mainnet: zkSync,
    testnet: zkSyncTestnet,
  },
];

const TestNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.testnet);

const MainNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.mainnet);

export const networks = process.env.REACT_APP_NETWORK === "mainnet" ? MainNetwork : TestNetwork;

export const MainNetworkSupported = [1, 137, 42161, 10, 324];
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const isMainNetWork = (chainId) => MainNetworkSupported.includes(+chainId);

const getDefaultNetwork = () => {
  const defaultNetworkId = process.env.REACT_APP_DEFAULT_CHAIN_ID;
  const defaultNetwork = networks.find(
    (network) => defaultNetworkId === `0x${network.id.toString(16)}`
  );

  if (!defaultNetwork) {
    return process.env.REACT_APP_NETWORK === "mainnet" ? mainnet : goerli;
  }
  return defaultNetwork;
};

export const defaultNetwork = getDefaultNetwork();
export const defaultProvider = new providers.JsonRpcProvider(
  process.env.REACT_APP_DEFAULT_RPC_URL || defaultNetwork.rpcUrls.default.http[0]
);

export const getNetworkLogoById = (chainId) => {
  const hexId = toHex(chainId);
  switch (hexId) {
    case CHAINID_NETWORK.ethereum:
      return EthereumIcon;
    case CHAINID_NETWORK.polygon:
      return PolygonIcon;
    case CHAINID_NETWORK.optimism:
      return OptimismIcon;
    case CHAINID_NETWORK.arbitrum:
      return ArbitrumIcon;
    case CHAINID_NETWORK.zkSync:
      return ZkSyncIcon;
    default:
      return null;
  }
};
