/* eslint-disable no-unused-vars */
import { useBorrowContext } from "context/contracts/BorrowContext";
import { BigNumber } from "ethers";
import { useMuteRouterContract } from "hooks/contract/core/useMuteContract";
import { useUniswapV2RouterContract } from "hooks/contract/core/useUniswapV2RouteContract";
import useWallet from "hooks/useWallet";
import { useMutation } from "react-query";
import { MainNetworkSupported, isEqualLowerString, isZksyncNetwork } from "utils/network";
import { getDataFromOpenOcean } from "utils/ethereum/getDataFromOpenOcean";
import { getPriceParaswap } from "utils/ethereum/getPriceParaswap";
import { formatUnits } from "utils/number";

const useRouterMutations = () => {
  const { useUniswapV2Router } = useUniswapV2RouterContract();
  const { useMuteRouter } = useMuteRouterContract();
  const { chainId, provider, account } = useWallet();
  const { decimalOfContractToken } = useBorrowContext();

  const getAmountOutUsingOpenOcean = async ({ maxAmountIn, inputToken, outputToken }) => {
    const { data } = await getDataFromOpenOcean(
      inputToken,
      outputToken,
      chainId,
      formatUnits(maxAmountIn, decimalOfContractToken[inputToken] || 18),
      provider,
      account
    );

    if (!data) {
      return {
        amountIn: maxAmountIn,
        amountOut: 0,
      };
    }

    const { outAmount } = data;

    return {
      amountIn: maxAmountIn,
      amountOut: outAmount,
    };
  };

  const getAmountOutUsingMute = async ({ maxAmountIn, inputToken, outputToken }) => {
    if (!useMuteRouter) {
      return { amountIn: maxAmountIn, amountOut: 0 };
    }
    const usdcToken = localStorage.getItem("usdcToken");
    const path = [inputToken];
    if (!isEqualLowerString(outputToken, usdcToken)) {
      path.push(usdcToken);
    }
    path.push(outputToken);

    const uniqPath = [...new Set(path)];

    const result = await useMuteRouter.getAmountsOut(maxAmountIn, uniqPath, [true]);

    return { amountIn: result[0], amountOut: result.at(-1) };
  };

  const getAmountOut = useMutation(
    async ({ maxAmountIn, inputToken, outputToken }) => {
      if (Number(maxAmountIn) === 0) {
        return { amountIn: maxAmountIn, amountOut: 0 };
      }
      if (MainNetworkSupported.includes(+chainId)) {
        const isZkSync = isZksyncNetwork(chainId);

        if (isZkSync) {
          const getAmountOutMethod = useMuteRouter
            ? getAmountOutUsingMute
            : getAmountOutUsingOpenOcean;
          const { amountIn, amountOut } = await getAmountOutMethod({
            inputToken,
            outputToken,
            maxAmountIn,
          });
          return { amountIn, amountOut };
        }

        const {
          priceRoute: { srcAmount, destAmount },
        } = await getPriceParaswap(
          { chainId },
          inputToken,
          outputToken,
          maxAmountIn,
          decimalOfContractToken[inputToken],
          decimalOfContractToken[outputToken]
        );

        return {
          amountIn: srcAmount,
          amountOut: destAmount,
        };
      }

      if (!useUniswapV2Router) {
        return { amountIn: maxAmountIn, amountOut: 0 };
      }
      const usdcToken = localStorage.getItem("usdcToken");
      const path = [inputToken];
      if (!isEqualLowerString(outputToken, usdcToken)) {
        path.push(usdcToken);
      }
      path.push(outputToken);

      const uniqPath = [...new Set(path)];

      if (BigNumber.from(maxAmountIn).eq(0)) {
        return { amountIn: maxAmountIn, amountOut: 0 };
      }
      const result = await useUniswapV2Router.getAmountsOut(maxAmountIn, uniqPath);

      return { amountIn: result[0], amountOut: result.at(-1) };
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  const getAmountIn = useMutation(
    async ({ amountOut, inputToken, outputToken }) => {
      if (Number(amountOut) === 0) {
        return { amountIn: 0, amountOut };
      }
      if (MainNetworkSupported.includes(+chainId)) {
        const isZkSync = isZksyncNetwork(chainId);

        if (isZkSync) {
          const { data } = await getDataFromOpenOcean(
            inputToken,
            outputToken,
            chainId,
            formatUnits(amountOut, decimalOfContractToken[outputToken] || 18),
            provider,
            account,

            decimalOfContractToken[outputToken]
          );

          const {
            outAmount,
            outToken: { decimals: outTokenDecimal },
            // maxAmountIn,
          } = data;

          return {
            // amountIn: maxAmountIn,
            amountOut: formatUnits(outAmount, outTokenDecimal),
          };
        }

        const {
          priceRoute: { srcAmount, destAmount },
        } = await getPriceParaswap(
          { chainId },
          inputToken,
          outputToken,
          amountOut,
          decimalOfContractToken[inputToken],
          decimalOfContractToken[outputToken],
          "BUY"
        );

        return {
          amountIn: srcAmount,
          amountOut: destAmount,
        };
      }

      if (!useUniswapV2Router) {
        return { amountIn: 0, amountOut };
      }
      const usdcToken = localStorage.getItem("usdcToken");
      const path = [inputToken];
      if (!isEqualLowerString(outputToken, usdcToken)) {
        path.push(usdcToken);
      }
      path.push(outputToken);

      const uniqPath = [...new Set(path)];

      const result = await useUniswapV2Router.getAmountsIn(amountOut, uniqPath);
      return { amountIn: result[0], amountOut: result.at(-1) };
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  return {
    getAmountOut: getAmountOut.mutateAsync,
    getAmountIn: getAmountIn.mutateAsync,
    isLoadingData: getAmountOut.isLoading,
  };
};

export default useRouterMutations;
