/* eslint-disable no-loop-func */
import { ethers } from "ethers";
import { useWallet } from "hooks";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { reduce } from "lodash";
import { useQuery } from "react-query";
import {
  calculateTotalValueInRequests,
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/multicall";
import { MainNetworkSupported } from "utils/network";
import { usePITContractRead } from "hooks/contract/usePITContract";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { useUniswapV2FactoryContract } from "hooks/contract/core/useUniswapV2FactoryContract";
import { useDecimalToken } from "../useDecimalToken";
import { handleGetDataForBorrowContextZkSync } from "./handleGetDataForBorrowContextZkSync";
import { prepareRequestMulticallZksync } from "./prepareRequestMulticallZksync";
import { useGetTokens } from "../useTokenSupported";

const usePrimaryIndexTokenMultiCall = () => {
  const { chainId, account } = useWallet();
  const isMainNet = MainNetworkSupported.includes(+chainId);

  const { loading, availableBorrowTokens, projectTokenList } = useGetTokens();
  const { data: decimalTokens } = useDecimalToken([...availableBorrowTokens, ...projectTokenList]);

  const { multiCallSMC } = useMultiCallContract();
  const {
    data: { PitContract },
  } = usePITContractRead();
  const { PriceContract } = usePriceContract();
  const { uniswapV2Factory: UniswapV2FactoryContract } = useUniswapV2FactoryContract();

  return useQuery(
    [
      "borrowed-pit-multicall",
      account,
      chainId,
      [...projectTokenList, ...availableBorrowTokens].map((o) => o.address).toString(),
    ],
    async () => {
      const listToken = [...projectTokenList];

      const listPrepareRequestMultical = await prepareRequestMulticallZksync({
        PITContract: PitContract,
        listToken,
        availableBorrowTokens,
        account,
        decimalTokens,
        PriceContract,
        chainId,
        UniswapV2FactoryContract,
      });

      const chunkSize = 150; // 100 batch
      const promises = [];
      const requestOfPromises = [];

      // loop chunk with group
      let buffer = [];
      for (let i = 0; i < listPrepareRequestMultical.length; i += 1) {
        if (buffer.length + listPrepareRequestMultical[i].length < chunkSize) {
          buffer = [...buffer, ...listPrepareRequestMultical[i]];
        } else {
          // flush
          const totalValue = calculateTotalValueInRequests(buffer);

          requestOfPromises.push(...buffer);
          promises.push(
            multiCallSMC.callStatic
              .aggregate3Value([...buffer], {
                value: totalValue,
              })
              .then((resultMulticall) => formatMulticallResults(resultMulticall))
              .catch((error) => {
                console.log("Multicall request fail", buffer);
                throw error;
              })
          );

          buffer = listPrepareRequestMultical[i]; // next round
        }
      }

      // remain batch
      if (buffer.length) {
        const totalValue = calculateTotalValueInRequests(buffer);

        requestOfPromises.push(...buffer);
        promises.push(
          multiCallSMC.callStatic
            .aggregate3Value([...buffer], {
              value: totalValue,
            })
            .then((resultMulticall) => formatMulticallResults(resultMulticall))
            .catch((error) => {
              console.log("Multicall request fail", buffer);
              throw error;
            })
        );
      }

      const allReturnData = await Promise.all(promises);
      const returnData = allReturnData.reduce((x, v) => [...x, ...v], []);

      const results = decodeResultMulticallZkSync(requestOfPromises, returnData);
      const {
        currentLimitLendingToken,
        currentLimitPrjToken,
        pitRemaining,
        totalPITCollateral,
        totalOutstandingAssets,
        accrualAssets,
        healthFactor,
        depositedAssets,
        fTokenAddresses,
        liquidationThresholdList,
        lvrLending,
        evaluations,
        borrwedList,
        pairToken,
      } = handleGetDataForBorrowContextZkSync(results, isMainNet);

      const newTotalOutStanding = reduce(
        totalOutstandingAssets,
        (res, value, key) => {
          const newData = {
            ...value,
            loanBody: ethers.BigNumber.from(value?.value || "0").toString(),
            value: ethers.BigNumber.from(value?.value || "0")
              .add(accrualAssets[key]?.value || 0)
              .toString(),
          };
          return {
            ...res,
            [key]: newData,
          };
        },
        {}
      );

      return {
        availableBorrowTokens,
        currentLimitLendingToken,
        currentLimitPrjToken,
        fTokenAddresses,
        projectTokenList,
        pitCollateralBalance: totalPITCollateral,
        pitRemaining,
        totalOutstandingAssets: newTotalOutStanding,
        accrualAssets,
        healthFactor,
        depositedAssets,
        liquidationThresholdList,
        lvrLending,
        evaluations,
        borrwedList,
        pairToken,
      };
    },
    {
      retry: 3,
      enabled:
        !!PitContract &&
        !!PriceContract &&
        !loading &&
        !!decimalTokens &&
        !!availableBorrowTokens?.length &&
        !!projectTokenList?.length &&
        !!account,
    }
  );
};

export default usePrimaryIndexTokenMultiCall;
